<template>
  <div class="access-item-unlock-confirm-modal alert-modal-content">
    <div class="heading-4">You have {{ freeHintsCount }} free review</div>
    <div class="paragraph">
      <div>Use your <span>free</span> review on this potential record match.</div>
      <div>This could be the missing piece of your family history you've been searching for!</div>
    </div>
    <div class="buttons">
      <mcr-button class="white" :disabled="loading" @click="close">Cancel</mcr-button>
      <mcr-button class="proceed" :loading="loading" @click="okClick">Review for Free</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {getHintDetailRoute} from '@common/utils/utils.routes';

export default {
  props: {
    personCode: String,
    hintId: String,
  },
  data() {
    return {
      loading: false,
      freeHintsCount: this.$store.getters.familyTreeHintsMetaState.free_hints_count,
    };
  },
  computed: {},
  methods: {
    okClick() {
      this.loading = true;
      this.network.accessItem
        .create({person_code: this.personCode})
        .then(response => {
          this.$store.commit('mutateFamilyTreeHintsMetaState', {free_hints_count: response.free_hints_count});
          this.close();
          this.$store.commit('setFamilyTreeHintsListState', null);
          this.$router.push(getHintDetailRoute(this.hintId));
        })
        .catch(() => {
          this.$toasted.error('Something went wrong. Please try again later.');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
  },
  components: {McrButton},
  name: 'AccessItemUnlockConfirmModal',
};
</script>

<style scoped lang="scss">
.access-item-unlock-confirm-modal {
  .heading-4 {
    margin-bottom: 8px;
  }
  .paragraph span {
    font-weight: 600;
  }
  .proceed {
    width: 180px;
  }
  @media only screen and (min-width: $breakpoint-phablet) {
    max-width: 430px;
  }
}
</style>
