import {isChineseText} from '@common/utils/utils';
import {getSubscriptionWallRoute} from '@common/utils/utils.routes';

export default {
  getMetaInfo(vm) {
    const meta = [
      {
        vmid: 'robots',
        name: 'robots',
        content: 'index,nofollow',
      },
    ];
    if (vm.personMentionsState) {
      return {
        title: `${vm.fullNameEn || ''} ${vm.fullNameCh || ''}`.trim(),
        meta,
      };
    }

    if (vm.$route.params.slug) {
      const words = vm.$route.params.slug.split('-');
      for (let i = 0; i < words.length; i++) {
        if (words[i]) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
      }
      return {
        title: words.join(' '),
        meta,
      };
    }
  },
  init(vm) {
    vm.$store.commit('setSourcesTextSearchCountState', 0);
    vm.$store
      .dispatch('getPersonMentionsAction', {code: vm.$route.params.code})
      .then(() => {
        if (vm.firstNameCh && vm.surnameCh) {
          const pros = {last_name: vm.surnameCh, q: vm.firstNameCh, only_count: true};
          vm.$store.dispatch('sourcesTextSearchCountAction', pros).then(results => {
            if (vm.sourcesTextSearchCountState === 0) {
              const pros = {clan_name: vm.surnameCh, only_meta: true};
              vm.$store.dispatch('searchClanPedigreesAction', pros);
            }
          });
        } else if (vm.firstSurname) {
          const pros = {clan_name: vm.firstSurname, only_meta: true};
          vm.$store.dispatch('searchClanPedigreesAction', pros);
        }

        const sourceId = vm.personMentionsState.source_mentions[0].id;
        vm.$store.dispatch('getPersonMentionsImagesAction', {code: vm.$route.params.code, sourceId});
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.is_offline) {
          vm.isRecordOffline = true;
        } else if (error.response && error.response.data && error.response.data.subscription_required) {
          const unknown = 'Unknown';
          const name = error.response && error.response.data ? error.response.data.full_name || unknown : unknown;
          vm.$router.replace(getSubscriptionWallRoute(vm.$route.fullPath, name));
        } else {
          vm.isRecordNotExist = true;
        }
      });
  },
  savePrevRoute(fromR, vm) {
    if (fromR.name) {
      const {name, query, params} = fromR;
      const fromSearch = name.includes('search') && !name.includes('detail');
      if (fromSearch || name.includes('familytree') || name.includes('hint-review')) {
        const hash = `#record-${vm.$route.params.code}`;
        vm.$store.commit('setMentionPrevRouteState', {name, query, params, hash});
      }
    }
  },
  getBackButtonLabel(prevRoute) {
    if (prevRoute && prevRoute.name === 'hint-review') {
      return 'Back to Record Review';
    }
    if (prevRoute && prevRoute.name.includes('familytree')) {
      return 'Back to Family Tree';
    }
    if (prevRoute && prevRoute.name.includes('search') && !prevRoute.name.includes('detail')) {
      const {first_name, surname} = prevRoute.query;
      const fullName = `${first_name || ''} ${surname || ''}`.trim();
      return fullName ? `Back to results for "${fullName}"` : `Back to search results`;
    }
    return prevRoute ? `Back` : '';
  },
  getFullNameEn(vm) {
    const firstName = vm.firstNamesEn.length ? vm.firstNamesEn[0].value : '';
    const surname = vm.surnamesEn.length ? vm.surnamesEn[0].value : '';
    const otherName = vm.otherNames.length ? vm.otherNames[0].value : '';
    return `${firstName} ${surname}`.trim() || otherName;
  },
  getFirstNamesEn(vm) {
    return vm.personMentionsState.first_names.filter(name => name.type !== 'other_name' && !isChineseText(name.value));
  },
  getFirstNamesCh(vm) {
    return vm.personMentionsState.first_names.filter(name => name.type !== 'other_name' && isChineseText(name.value));
  },
  getSurnamesEn(vm) {
    return vm.personMentionsState.surnames.filter(name => !isChineseText(name.value));
  },
  getSurnamesCh(vm) {
    return vm.personMentionsState.surnames.filter(name => isChineseText(name.value));
  },
  getOtherNames(vm) {
    return vm.personMentionsState.first_names.filter(name => name.type === 'other_name');
  },
  mentionsImagesGetNextPage(vm) {
    const meta = vm.$store.getters.personMentionsImagesMetaState;
    const payload = {code: meta.code, sourceId: meta.source_id, page: meta.offset / meta.limit + 2};
    vm.$store.dispatch('getPersonMentionsImagesAction', payload).then(() => {
      const data = vm.$store.getters.personMentionsImagesState;
      vm.$store.commit('setGalleryItemsState', this.mentionsImagesGetGalleryData(data, meta));
    });
  },
  mentionsImagesGetGalleryData(items, meta) {
    let data = items.map(item => ({...item, _id: item.id, src: item.url}));
    if (data.length < meta.total_count) {
      data.push({_id: 'loading', isLoading: true});
    }
    return data;
  },
  getViewerRoute(priority, id, sourceId, zupuId) {
    const query = {page: priority, page_id: id};
    return {name: 'source-viewer', params: {sourceId}, query};
  },
  getFilteredOtherMentions(units, sourceMentions) {
    let otherMentions = {};
    let unitPersonCodes = [];
    for (let unit of units) {
      for (let p of unit.persons) {
        unitPersonCodes.push(p.code);
      }
    }
    for (let source of sourceMentions) {
      otherMentions[source.id] = [];
      for (let otherMention of source.other_mentions || []) {
        if (unitPersonCodes.indexOf(otherMention.code) === -1) {
          otherMentions[source.id].push(otherMention);
        }
      }
    }
    return otherMentions;
  },
  isDataProtected(vm) {
    return vm.personMentionsState.is_potentially_living && !vm.userIsStaffState;
  },
  showRelativesTable(vm) {
    const relatedPersons = vm.personMentionsState.related_persons;
    const relativesJson = vm.personMentionsState.relatives_json;
    return (relatedPersons && relatedPersons.length) || (relativesJson && relativesJson.length);
  },
  async onClickImage(image, sourceId, zupuId, vm) {
    // when person is mentioned in multiple sources, the first source images are fetched on page load, if user opens other source, fetch it's images first
    if (sourceId !== vm.$store.getters.personMentionsImagesMetaState.source_id) {
      await vm.$store.dispatch('getPersonMentionsImagesAction', {code: vm.$route.params.code, sourceId});
    }
    const data = vm.$store.getters.personMentionsImagesState;
    const meta = vm.$store.getters.personMentionsImagesMetaState;

    const source = vm.personMentionsState.source_mentions.find(s => s.id === sourceId);
    const sourceName = `${source.title_en || ''} ${source.title_ch || ''}`.trim();

    const galleryMeta = {
      personName: `${vm.fullNameEn} ${vm.fullNameCh}`,
      totalCount: meta.total_count,
      sourceName: sourceName,
      getViewerRoute: (priority, id) => {
        const query = {page: priority, page_id: id};
        return {name: 'source-viewer', params: {sourceId}, query};
      },
    };
    vm.$store.commit('setGalleryMetaState', galleryMeta);
    vm.$store.commit('setGalleryItemsState', this.mentionsImagesGetGalleryData(data, meta));
    vm.$store.dispatch('displayGalleryAction', image.id);
  },
};
