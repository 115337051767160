<template>
  <page-not-found v-if="pageNotFound"></page-not-found>
  <div v-else-if="noAccess" class="hints-page no-access">
    <div class="readable_content">
      <div class="heading">
        <div class="bold">Review Record Matches</div>
      </div>

      <div class="tree-selector">
        <family-tree-selector
          :list="familyTreeListState"
          :tree-id="familyTreeId"
          :tree-name="'No Access to Family Tree'"
          :display-inline="true"
          :only-owned="true"
          @select="onTreeSelect"
        ></family-tree-selector>
      </div>

      <div class="no-access-notification">
        <div class="icon-container"><lock-icon :size="28"></lock-icon></div>
        <h6>You don't have access to RecordFinder™ for this tree</h6>
        <router-link class="link" :to="ownedTreeHintsRoute">View matches for {{ ownedFamilyTree.name }}</router-link>
      </div>
    </div>
  </div>
  <div class="hints-page" v-else>
    <div class="readable_content">
      <div class="heading">
        <div class="bold">Review Record Matches</div>
      </div>

      <div class="tree-selector" v-if="showSelector">
        <family-tree-selector
          :list="familyTreeListState"
          :tree-id="familyTreeId"
          :tree-name="familyTreeName"
          :display-inline="true"
          :only-owned="true"
          :show-manage="false"
          :with-hints="true"
          @select="onTreeSelect"
        ></family-tree-selector>
      </div>

      <div class="header no-border">
        <div class="heading-6">RecordFinder™</div>
        <gradient-banner v-if="showFreeHintsCount" class="text-sm"
          >{{ freeHintsCount }} Free Review Available</gradient-banner
        >
      </div>
      <tabs-bar ref="tabs" :start-tab="activeTab" @activate-tab="onActivateTab">
        <tabs-block-item :name="newTabName" identifier="new"> </tabs-block-item>
        <tabs-block-item :name="acceptedTabName" identifier="accepted"> </tabs-block-item>
        <tabs-block-item :name="ignoredTabName" identifier="ignored"> </tabs-block-item>
      </tabs-bar>

      <div class="content" v-if="loading">
        <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
      </div>
      <div class="content" v-else-if="itemsLoaded">
        <person-hint-item
          v-for="hint in familyTreeHintsListState"
          :hint="hint"
          :data-clickable="hintsClickable"
          :data-visible="isHintVisible(hint)"
          :key="hint.id"
        >
          <template v-slot:tag>
            <div class="tag-wrapper">
              <person-link-tag :person="hint.person" :tree-id="familyTreeId"></person-link-tag>
            </div>
          </template>
        </person-hint-item>

        <base-pagination
          :meta="familyTreeHintsMetaState"
          :show-jump-to="false"
          @onSwitchPage="onSwitchPage"
        ></base-pagination>
      </div>
      <div class="content empty-hints" v-else-if="showEmptyState">
        <div v-if="activeTab === 'new'" class="new-empty-hints">
          <div class="heading-5 bold">No new matching records</div>
          <div class="sub-title">To discover more records:</div>
          <div class="list">
            <div class="text-sm list-item"><check-icon :size="20" />Provide dates for birth, marriage, etc.</div>
            <div class="text-sm list-item">
              <check-icon :size="20" />Provide place details for birth, residence, or death.
            </div>
            <div class="text-sm list-item">
              <check-icon :size="20" />Add Chinese names to unlock hints to precious China-based records.
            </div>
            <div class="text-sm list-item"><check-icon :size="20" />Add more persons to your tree.</div>
          </div>
          <mcr-button-router-link :to="ftRoute" class="bold"
            ><tree-icon :size="20" /><span>Add More Details</span></mcr-button-router-link
          >
        </div>
        <div v-else class="default-empty-hints">You don't have any {{ activeTab }} record matches.</div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/BasePagination.vue';
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import GradientBanner from '@common/elements/layouts/GradientBanner.vue';
import TabsBar from '@common/elements/tabs/TabsBar';
import TabsBlockItem from '@common/elements/tabs/TabsBlockItem';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import CheckIcon from 'vue-material-design-icons/Check';
import TreeIcon from 'vue-material-design-icons/FileTree';
import LockIcon from 'vue-material-design-icons/LockOutline';
import {mapGetters} from 'vuex';

import FamilyTreeSelector from '@/components/common/inputs/familyTreeSelector';
import {openHintsPaywallModal} from '@/components/common/subscriptions/utils';
import PersonHintItem from '@/components/common/tree/PersonHintItem';

import PersonLinkTag from '@/components/modules/familyTreeHints/PersonLinkTag';
import PageNotFound from '@/components/page.not.found';

const DEFAULT_TAB = 'new';

export default {
  metaInfo() {
    const treeName =
      this.familyTreeHintsMetaState && this.familyTreeHintsMetaState.family_tree
        ? this.familyTreeHintsMetaState.family_tree.name
        : 'Family Tree';
    return {
      title: `RecordFinder - ${treeName}`,
    };
  },
  data() {
    return {
      activeTab: this.$route.query.tab || DEFAULT_TAB,
      pageNotFound: false,
      noAccess: false,
    };
  },
  created() {
    this.$store.dispatch('fetchFamilyTreeListAction');
    if (this.$route.query.paywall) {
      let query = omit(this.$route.query, 'paywall');
      this.$router.replace({query}).catch(() => {});
      openHintsPaywallModal(this);
    }
  },
  watch: {
    $route: {
      handler: function (toRoute, fromRoute) {
        const sameRoute = toRoute.name === fromRoute.name;
        const changedFtId = parseInt(toRoute.params.id) !== parseInt(fromRoute.params.id);
        const newTab = toRoute.query.tab || DEFAULT_TAB;
        const changedTab = newTab !== fromRoute.query.tab;

        /* activateTab calls fetch hints, so only manually re-fetch hints if tab is not changed */

        if (sameRoute && changedTab) {
          return this.$refs.tabs.activateTab(newTab);
        }
        if (sameRoute && changedFtId) {
          this.pageNotFound = false;
          this.noAccess = false;
          return this.fetchHints(1, newTab);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'familyTreeHintsListState',
      'familyTreeHintsMetaState',
      'familyTreeHintsLoadingState',
      'familyTreeListState',
      'userHintsTreeIdState',
      'userFamilyTreeIdState',
      'userIsStaffState',
    ]),
    loading() {
      return this.familyTreeHintsLoadingState || isEmpty(this.familyTreeHintsMetaState);
    },
    isQueryParamsApplied() {
      const familyTree = this.familyTreeHintsMetaState.family_tree || {};
      return (
        this.$route.params.id == familyTree.id &&
        this.$route.query.person_id == this.familyTreeHintsMetaState.person_id &&
        this.$route.query.tab === this.$refs.tabs.tabActiveId &&
        this.familyTreeHintsMetaState.status === this.$route.query.tab
      );
    },
    familyTreeId() {
      return parseInt(this.$route.params.id);
    },
    familyTreeName() {
      return this.familyTreeHintsMetaState.family_tree && this.familyTreeHintsMetaState.family_tree.name;
    },
    personId() {
      return this.$route.query.person_id;
    },
    filters() {
      let filters = {};
      if (this.personId) {
        filters.person_id = this.personId;
      }
      return filters;
    },
    showSelector() {
      const inList = this.familyTreeListState.find(item => item.object_id === this.familyTreeId);
      if (inList) {
        return this.familyTreeListState.length > 1;
      }
      return this.familyTreeId && this.familyTreeName;
    },
    itemsLoaded() {
      return !this.familyTreeHintsLoadingState && this.familyTreeHintsListState.length;
    },
    showEmptyState() {
      return !this.familyTreeHintsLoadingState && !this.familyTreeHintsListState.length;
    },
    showCountInTabName() {
      const meta = this.familyTreeHintsMetaState;
      if (meta) {
        const treeId = meta.family_tree ? meta.family_tree.id : null;
        return !meta.person_id && treeId && this.familyTreeId && treeId === this.familyTreeId;
      }
      return false;
    },
    newTabName() {
      const facets = this.familyTreeHintsMetaState ? this.familyTreeHintsMetaState.facets : {};
      const newLabel = 'New Matches';
      return this.showCountInTabName ? `${newLabel} (${facets.status.new})` : newLabel;
    },
    acceptedTabName() {
      const facets = this.familyTreeHintsMetaState ? this.familyTreeHintsMetaState.facets : {};
      return this.showCountInTabName ? `Accepted (${facets.status.accepted})` : 'Accepted';
    },
    ignoredTabName() {
      const facets = this.familyTreeHintsMetaState ? this.familyTreeHintsMetaState.facets : {};
      return this.showCountInTabName ? `Ignored (${facets.status.ignored})` : 'Ignored';
    },
    hintsVisible() {
      return this.familyTreeHintsMetaState ? this.familyTreeHintsMetaState.hints_visible : false;
    },
    hintsClickable() {
      return this.familyTreeHintsMetaState.is_owner || this.userIsStaffState;
    },
    ftRoute() {
      return {name: 'familytree-details', params: {id: this.familyTreeId}};
    },
    ownedFamilyTree() {
      const treeId = this.userHintsTreeIdState || this.userFamilyTreeIdState;
      return this.familyTreeListState.find(item => item.object_id.toString() === treeId.toString()) || {};
    },
    ownedTreeHintsRoute() {
      return {name: 'familytree-hints', params: {id: this.ownedFamilyTree.object_id}};
    },
    showFreeHintsCount() {
      return this.freeHintsCount && this.familyTreeHintsMetaState.is_owner && !this.hintsVisible;
    },
    freeHintsCount() {
      return this.familyTreeHintsMetaState.free_hints_count;
    },
  },
  methods: {
    fetchHints(page, status) {
      this.$store
        .dispatch('fetchFamilyTreeHintsAction', {family_tree_id: this.familyTreeId, page, status, ...this.filters})
        .catch(error => {
          if (error.response && error.response.status === 403) {
            this.noAccess = true;
            return;
          }
          this.pageNotFound = true;
        });
    },
    isHintVisible(hint) {
      return this.hintsVisible || hint.hint_visible;
    },
    onActivateTab({tabId, fromTabId}) {
      if (!fromTabId && this.isQueryParamsApplied && this.familyTreeHintsListState.length) {
        return;
      }
      if (this.$route.query.tab !== tabId) {
        const query = Object.assign({}, this.$route.query, {tab: tabId});
        this.$router.push({query}).catch(() => {});
        AnalyticsMainHandler.trackHintsFilterClickEvent(tabId, getRoutePageName(this.$route));
      }
      this.activeTab = tabId;
      this.fetchHints(1, tabId);
    },
    onSwitchPage(page) {
      this.fetchHints(page, this.$route.query.tab);
    },
    onTreeSelect(value) {
      this.$router.push({name: 'familytree-hints', params: {id: value.object_id}, query: {tab: 'new'}});
    },
  },
  components: {
    GradientBanner,
    McrButtonRouterLink,
    FamilyTreeSelector,
    TabsBlockItem,
    TabsBar,
    PersonHintItem,
    BasePagination,
    PageNotFound,
    CheckIcon,
    TreeIcon,
    PersonLinkTag,
    LockIcon,
  },
  name: 'HintsPage',
};
</script>

<style lang="scss" scoped>
.hints-page {
  .tree-selector::v-deep {
    padding: 10px 0 0;
    .family-tree-multiselect {
      max-width: fit-content;
      width: fit-content;

      &.multiselect--active {
        max-width: 380px;
        width: 380px;
      }

      @media only screen and (max-width: $breakpoint-mobile) {
        max-width: 100%;
        &.multiselect--active {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
  .heading {
    display: flex;
    justify-content: space-between;
  }

  .header {
    margin-top: 20px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $background-light;
    .heading-6 {
      word-break: break-word;
      color: $neutral-600;
    }
  }
  .tabs-bar {
    &::v-deep .tabs-header {
      border-bottom: 1px solid $neutral-200;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background: $background-light;

    .tag-wrapper {
      display: flex;
      margin-bottom: 12px;
      align-items: flex-start;
    }
  }
  .content.empty-hints {
    flex-direction: column;
    align-items: center;
  }

  .default-empty-hints {
    color: $neutral-500;
    padding: 16px;
  }
  .new-empty-hints {
    padding: 40px 24px;
    .sub-title {
      margin-top: 4px;
      color: $neutral-500;
    }
    .list {
      margin: 20px 0 32px;
    }
    .list-item {
      display: flex;
      margin-bottom: 16px;

      .material-design-icon {
        color: $primary-400;
        margin-right: 8px;
      }
    }
  }

  .person-hint-item::v-deep {
    &:first-of-type {
      border-top: none;
    }
  }

  .pagination {
    border-top: 1px solid $mcr-grey-tan;
    padding: 12px 16px;
    width: unset;
    margin-top: 0;
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    .readable_content {
      padding: 25px 0;
    }
    .heading {
      padding: 0 16px;
    }
    .tree-selector {
      padding: 10px 16px 0;
    }
  }
}

.hints-page.no-access {
  .no-access-notification {
    width: 100%;
    background: $background-light;
    padding: 48px 24px 48px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22px;

    h6 {
      margin: 12px 0 16px;
    }

    .icon-container {
      border-radius: 50%;
      background: $primary-50;
      width: 56px;
      height: 56px;
      color: $primary-400;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
