<template>
  <div class="gradient-banner">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'GradientBanner',
};
</script>

<style scoped lang="scss">
.gradient-banner {
  padding: 4px 12px;
  border-radius: 4px;
  outline: 1px solid $neutral-100;
  color: white;
  font-weight: 600;
  background-image: linear-gradient($primary-200, $primary-600);
}
</style>
